<template>
  <div class="app-container" v-loading.fullscreen.lock="fullscreenLoading">
    <header-menu :showTitle="false" logoTitle="学校中心" :menuItems="menuItems" />
    <div class="app-content-wrap">
      <router-view v-if="isAuthenticated" />
    </div>
    <footer-info />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import HeaderMenu from "@/components/Common/HeaderMenu";
import FooterInfo from "@/components/Common/FooterInfo";
import { AuditStatusEnum, StaffTypeEnum } from "@/utils/constant";
export default {
  components: { HeaderMenu, FooterInfo },
  name: "SchoolLayout",
  data() {
    return {
      activeIndex: "home",
      isAuthenticated: false,
      menuItems: [
        { title: "工作台", path: "/school" },
        // { title: "招生管理", path: "/school/recruit" },
        { title: "课程管理", path: "/school/course" },

        {
          title: "新闻横幅",
          path: "/school/banner-management",
          submenus: [
            { title: "横幅管理", path: "/school/banner-management" },
            { title: "新闻管理", path: "/school/news-management" },
          ],
        },
        // { title: "企业搜索", path: "/school/enterprise" },
        // { title: "在线沟通", path: "/school/chat" },
        // { title: "我的收藏", path: "/school/favorite" },
        // { title: "招生简章", path: "/school/enrollmentnews" },
        { title: "成果管理", path: "/school/achievementShow" },
        { title: "合作企业", path: "/school/enterpriselist" },

        {
          title: "项目班",
          path: "/school/practice/arrange",
          submenus: [
            { title: "项目班", path: "/school/practice/arrange" },
            { title: "招生动态", path: "/school/recruitnews" },
            // { title: "项目班评价", path: "/school/arrangeEvaluate" },
          ],
        },
        { title: "运行监控", path: "visual_analysis" },
        // { title: "招工管理", path: "/school/job" }
      ],
      fullscreenLoading: true,
    };
  },
  computed: {
    ...mapGetters(["employee"]),
  },
  methods: {
    init() {
      const { Employer } = this.employee.find(({ StaffType,IsAdmin }) => IsAdmin && StaffType === StaffTypeEnum.School) || {};
      if (Employer) {
        this.fullscreenLoading = false;
        this.isAuthenticated = true;
        return;
      }
      this.$confirm("暂无访问权限！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        showCancelButton: false,
        type: "warning",
      })
        .then(() => this.$router.push(`/`))
        .catch((err) => {
          this.$router.push(`/`);
        });
    },
  },
  created() {
    this.init();
  },
};
</script>
